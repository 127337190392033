<template>
  <div class="home-summary">
    <div class="wrapper">
      <div class="item">
        <div class="title">{{ $t("home.height") }}</div>
        <div class="number">{{ $HelperTools.toFinancialVal(height) }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t("home.validators") }}</div>
        <div class="number">{{ validator }}/{{ validator }}</div>
      </div>
      <div class="item">
        <div class="title">{{ $t("home.time") }}</div>
        <div class="number">{{ time }} s</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["height", "validator", "time"],
};
</script>

<style lang="scss" scoped>
.home-summary {
  width: 1080px;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: baseline;
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
  .s-title {
    @media (max-width: $breakpoint-sm) {
      padding: 0px 32px;
    }
  }
  .wrapper {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 40px 30px;
    @media (max-width: $breakpoint-xl) {
      grid-template-columns: auto auto auto auto auto;
      padding: 1.5em 1em;
      column-gap: 1em;
    }
    @media (max-width: $breakpoint-sm) {
      display: grid;
      grid-template-columns: 100%;
      row-gap: 0.5em;
    }
    .item:hover {
      width: 300px;
      height: 87px;
      background: #0060ff;
      box-shadow: 0px 20px 40px 0px rgba(0, 96, 255, 0.3);
      border-radius: 4px;
      transition: all ease 0.3s;
      .title {
        color: rgba(255, 255, 255, 0.6);
      }
      .number {
        color: rgba(255, 255, 255, 1);
      }
    }
    .item {
      cursor: pointer;
      padding: 20px;
      width: 300px;
      height: 87px;
      background: #fafafa;
      border-radius: 4px;
      display: flex;
      flex-flow: column;
      align-items: baseline;
      transition: all ease 0.3s;
      @media (max-width: $breakpoint-sm) {
        width: 100%;
        text-align: left;
        white-space: nowrap;
      }
      .title {
        font-size: 12px;
        font-family: Avenir-Book, Avenir;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.6);
        line-height: 16px;
      }
      .number {
        margin-top: 10px;
        font-size: 16px;
        font-family: DINPro-Medium, DINPro;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        line-height: 21px;
      }
    }
  }
}
</style>
